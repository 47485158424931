/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React, { useState, useEffect, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { useGoogleMaps } from "~hooks";
import { Grid, Go, Button } from "~components";
import { ReactComponent as Spoon } from "~assets/icons/spoon.svg";
import { MEDIA_QUERIES } from "~utils/helpers";

const Article = styled.article`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;

  ${MEDIA_QUERIES.desktop} {
    grid-column: span 4;
    margin-bottom: 0;
  }
`;

const Heading = styled.h2`
  text-transform: uppercase;
  margin-bottom: 2rem;
`;

const Subheading = styled.p`
  width: 100%;
  margin-bottom: 2rem;

  ${MEDIA_QUERIES.desktop} {
    width: 80%;
  }
`;

const SearchBar = styled.input`
  width: 100%;
  height: 44px;
  padding: 0 1rem;
  border-radius: 36px;
  border: none;
  text-overflow: ellipsis;

  ${MEDIA_QUERIES.desktop} {
    width: 80%;
  }
`;

const MapContainer = styled.article`
  width: 100%;
  height: 651px;
  border-radius: 1.25rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  grid-column: 1 / -1;

  ${MEDIA_QUERIES.desktop} {
    height: 560px;
    grid-column: 5 / -1;
    flex-direction: row;
  }
`;

const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  flex: 2;
`;

const MapList = styled.ul`
  width: 100%;
  background: var(--color-white);
  color: var(--color-black);
  padding: 2rem 1rem;
  flex: 1;
  overflow-y: scroll;

  ${MEDIA_QUERIES.desktop} {
    width: calc(100% / 2);
  }
`;

const MapListItem = styled.li`
  padding-bottom: 0.5rem;
  border-bottom: 1px solid;
  margin-bottom: 1rem;
`;

const MapListButton = styled.button`
  position: relative;
  display: flex;
  gap: 2ch;
  text-align: left;
`;

const DISTANCE_MATRIX_THRESHOLD = 0.3;
const START_LOCATION = {
  lat: -26.5984274,
  lng: 134.0060596
};
const START_ZOOM = 4;
const PANNED_ZOOM = 12;
const SELECTED_ZOOM = 18;

const MapSection = ({
  data: { backgroundColor, fontColor, heading, subheading }
}) => {
  const { isDesktop } = useBreakpoint();

  // ===========================================================================
  // maps init

  const { ref, map, google } = useGoogleMaps(
    process.env.GATSBY_GOOGLE_MAP_API,
    {
      center: START_LOCATION,
      zoom: START_ZOOM,
      clickableIcons: false,
      fullscreenControl: false,
      mapTypeControl: false,
      rotateControl: false,
      scaleControl: false,
      streetViewControl: false,
      zoomControl: true
    }
  );

  // ===========================================================================
  // context / ref / state

  const [stockists, setStockists] = useState([]);
  const [proximityStockists, setProximityStockists] = useState([]);
  const [selectedStockist, setSelectedStockist] = useState(null);
  const prevMarkersRef = useRef([]);

  const { allSanityGooglePlace } = useStaticQuery(graphql`
    query Stockists {
      allSanityGooglePlace(sort: { fields: name, order: ASC }) {
        edges {
          node {
            _id
            name
            address
            location {
              lat
              lng
            }
          }
        }
      }
    }
  `);

  // ===========================================================================
  // methods

  /*
   * drawMarkers() - accept location data and draw stuff on the map
   */
  const drawMarkers = (locations) => {
    const infowindow = new google.maps.InfoWindow();

    // Clear All Markers
    prevMarkersRef.current.forEach((marker) => {
      marker.setMap(null);
    });

    locations.forEach((location) => {
      // Create Markers
      const marker = new google.maps.Marker({
        position: location.location,
        map
      });

      // push to mutable ref array
      prevMarkersRef.current.push(marker);

      marker.addListener(`click`, () => {
        infowindow.setContent(
          `<p className="b2" style="color: black;">${location.name}<br />${location.address}</p>`
        );
        infowindow.setPosition(location.location);
        infowindow.setOptions({ pixelOffset: new google.maps.Size(0, -40) });
        infowindow.open(map);

        setSelectedStockist(location);
      });

      return marker;
    });
  };

  /*
   * initAutocompleteWidget() - does what it says on the tin, + listeners
   */
  const initAutocompleteWidget = () => {
    // define options
    const placesAutoCompleteInputElement = document.getElementById(`pac-input`);

    const options = {
      types: [`geocode`],
      componentRestrictions: { country: `au` },
      map
    };

    // initialize
    const autocomplete = new google.maps.places.Autocomplete(
      placesAutoCompleteInputElement,
      options
    );

    autocomplete.setFields([`address_components`, `geometry`, `name`]);

    // listen
    map.addListener(`bounds_changed`, () => {
      autocomplete.setBounds(map.getBounds());
    });

    // invisible, pannable reference marker
    const originMarker = new google.maps.Marker({ map });
    originMarker.setVisible(false);

    let originLocation = map.getCenter();

    // on place change
    autocomplete.addListener(`place_changed`, async () => {
      originMarker.setVisible(false);
      originLocation = map.getCenter();

      const place = autocomplete.getPlace();

      const names = place.address_components.map(
        (address) => address.long_name
      );
      const address = names.join(` `);

      // GTM
      if (window?.dataLayer) {
        window.dataLayer.push({
          event: `stockist_search`,
          address
        });
      }

      if (!place?.geometry?.location) {
        return;
      }

      originLocation = place.geometry.location;

      map.setCenter(originLocation);
      map.setZoom(PANNED_ZOOM);

      originMarker.setPosition(originLocation);
      originMarker.setVisible(false);

      const localPlaceSubset = [];
      const referenceLat = place.geometry.location.lat();
      const referenceLng = place.geometry.location.lng();

      stockists.forEach((stockist) => {
        const { lat, lng } = stockist?.location;

        // if not within boundaries, then return
        if (
          lat < referenceLat - DISTANCE_MATRIX_THRESHOLD ||
          lat > referenceLat + DISTANCE_MATRIX_THRESHOLD ||
          lng < referenceLng - DISTANCE_MATRIX_THRESHOLD ||
          lng > referenceLng + DISTANCE_MATRIX_THRESHOLD
        ) {
          return;
        }

        // calculate distance from originLocation

        const distanceLoc = new google.maps.LatLng(lat, lng);
        const computedDist =
          google.maps.geometry.spherical.computeDistanceBetween(
            distanceLoc,
            originLocation
          );

        stockist.distance = Math.round(computedDist / 1000);

        localPlaceSubset.push(stockist);
      });

      localPlaceSubset.sort((a, b) => a.distance - b.distance);
      setProximityStockists(localPlaceSubset);
    });
  };

  // ===========================================================================
  // lifecycle

  /*
   * useEffect[] set the stockists to the Sanity data
   */
  useEffect(() => {
    setStockists(allSanityGooglePlace.edges.map(({ node }) => node));
  }, []);
  /*
   * useEffect[map] initialize the markers and the autocomplete on map load
   */
  useEffect(() => {
    if (!map) {
      return;
    }

    initAutocompleteWidget();
    drawMarkers(stockists);
  }, [map]);

  /*
   * useEffect[proximityStockists] redraw the markers
   */
  useEffect(() => {
    if (!map) {
      return;
    }

    drawMarkers(proximityStockists);
  }, [proximityStockists]);

  /*
   * useEffect[selectedStockist] pan to the selectedStockist when it changes
   */
  useEffect(() => {
    if (!map) {
      return;
    }

    let zoom = START_ZOOM;
    let pan = START_LOCATION;

    if (selectedStockist) {
      zoom = SELECTED_ZOOM;
      pan = selectedStockist?.location;
    }

    map.setZoom(zoom);
    map.panTo(pan);
  }, [selectedStockist]);

  // ===========================================================================
  // render

  return (
    <div
      css={css`
        padding: 5rem 0;
      `}
      id="map-section"
    >
      <Grid
        _css={css`
          background: ${backgroundColor?.hex || `var(--color-white)`};
          color: ${fontColor.hex || `var(--color-black)`};
        `}
      >
        <Article>
          <div>
            <Heading className="h2">{heading}</Heading>

            <div id="pac-card">
              <Subheading id="pac-title" className="b1">
                {subheading}
              </Subheading>

              <div id="pac-container">
                <SearchBar
                  id="pac-input"
                  type="text"
                  placeholder="Enter an address"
                  className="b2 pac-target-input"
                  autoComplete="off"
                />
              </div>
            </div>
          </div>

          {isDesktop && (
            <div
              css={css`
                width: 100%;
                margin-top: 4rem;
              `}
            >
              <Heading className="h2">Buy Us Online</Heading>

              <div
                css={css`
                  display: flex;
                  flex-direction: column;

                  ${MEDIA_QUERIES.desktop} {
                    flex-direction: row;
                  }
                `}
              >
                <Go
                  to="https://shop.coles.com.au/a/national/everything/search/denada?cid=affiliate_social_20200831_denada_Shop-now"
                  _css={css`
                    width: max-content;
                    margin-bottom: 1rem;

                    ${MEDIA_QUERIES.desktop} {
                      margin-bottom: 0;
                    }
                  `}
                >
                  <Button text="Coles" color="light" />
                </Go>

                <Go
                  to="https://www.woolworths.com.au/shop/search/products?searchTerm=denada%20sugar%20free"
                  _css={css`
                    width: max-content;

                    margin-left: 0;

                    ${MEDIA_QUERIES.desktop} {
                      margin-left: 1rem;
                    }
                  `}
                >
                  <Button text="Woolworths" color="light" />
                </Go>
              </div>
            </div>
          )}
        </Article>

        <MapContainer>
          <MapList>
            {proximityStockists?.[0] ? (
              proximityStockists.map((stockist) => (
                <MapListItem
                  key={`stockist-list-item-${stockist._id}`}
                  css={css`
                    ${JSON.stringify(selectedStockist?.location) ===
                    JSON.stringify(stockist.location)
                      ? `var(--color-caramel-red)`
                      : `var(--color-gray)`};
                  `}
                >
                  <MapListButton
                    type="button"
                    onClick={() => {
                      setSelectedStockist(stockist);
                    }}
                  >
                    <div
                      css={css`
                        display: flex;
                        align-items: start;
                        gap: 1ch;
                      `}
                    >
                      <Spoon
                        css={css`
                          width: 7px;
                          height: auto;
                          fill: var(--color-peanut-red);
                          stroke: var(--color-white);
                        `}
                      />
                      <p className="b2">{stockist?.distance}km</p>
                    </div>

                    <div>
                      <h3 className="b1">{stockist.name}</h3>
                      <p className="b2">{stockist.address}</p>
                    </div>
                  </MapListButton>
                </MapListItem>
              ))
            ) : (
              <li>
                <p className="b1">
                  Enter your city or postal code to find nearby stores.
                </p>
              </li>
            )}
          </MapList>

          <MapWrapper>
            <div ref={ref} style={{ width: `100%`, height: `100%` }} />
          </MapWrapper>
        </MapContainer>

        {!isDesktop && (
          <div
            css={css`
              width: 100%;
              margin-top: 2.5rem;
              grid-column: 1/-1;
            `}
          >
            <Heading className="h2">Buy Us Online</Heading>

            <div
              css={css`
                display: flex;
                flex-direction: column;

                ${MEDIA_QUERIES.desktop} {
                  flex-direction: row;
                }
              `}
            >
              <Go
                to="https://shop.coles.com.au/a/national/everything/search/denada?cid=affiliate_social_20200831_denada_Shop-now"
                _css={css`
                  width: max-content;
                  margin-bottom: 1rem;

                  ${MEDIA_QUERIES.desktop} {
                    margin-bottom: 0;
                  }
                `}
              >
                <Button text="Coles" color="light" />
              </Go>

              <Go
                to="https://www.woolworths.com.au/shop/search/products?searchTerm=denada%20sugar%20free"
                _css={css`
                  width: max-content;

                  margin-left: 0;

                  ${MEDIA_QUERIES.desktop} {
                    margin-left: 1rem;
                  }
                `}
              >
                <Button text="Woolworths" color="light" />
              </Go>
            </div>
          </div>
        )}
      </Grid>
    </div>
  );
};

export default MapSection;

export const query = graphql`
  fragment MapSectionFragment on SanityMapSection {
    _type

    backgroundColor {
      hex
      title
    }

    fontColor {
      hex
      title
    }

    heading

    subheading
  }
`;
